import React from 'react'

import { Step, Box, Grid } from '@mui/material'
import { GridStepper } from '@front/squirtle'

import FormHeader from '@abra/elements/headers/Form'
import PrimarySection from './Section/Primary'
import POISection from './Section/POI'

const CommercialFormulaDetails = ({ record_id, ...props }) => <>
  <Box className='safearea'>
    <Grid container justifyContent='center' rowSpacing={3}>
      <Grid item xs={11} >
        <FormHeader />
      </Grid>
      <Grid item xs={11} >
        <GridStepper groupsCount={[3]} activeStep={0} orientation="vertical">
          <Step>  <PrimarySection {...props} /> </Step>
          {!!record_id && <Step> <POISection {...props} /> </Step>}
        </GridStepper>
      </Grid>
    </Grid>
  </Box>
</>

export default React.memo(CommercialFormulaDetails)
